<template>
  <div>
    <b-card no-body>
      <b-card-header v-b-toggle.filterBody>
        Фильтр
      </b-card-header>
      <b-collapse
        id="filterBody"
        visible
        class="card-body"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Год"
              >
                <b-form-input
                  v-model="filter.year"
                  type="number"
                  min="1900"
                  autocomplete="false"
                  placeholder="Введите год"
                  @input="updateFilter('year')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Неделя от"
              >
                <b-form-input
                  v-model="filter.week_from"
                  type="number"
                  min="0"
                  max="53"
                  autocomplete="false"
                  placeholder="Введите номер недели от"
                  @input="updateFilter('week_from')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="form-group-el-select"
                label="Неделя до"
              >
                <b-form-input
                  v-model="filter.week_to"
                  type="number"
                  min="0"
                  max="53"
                  autocomplete="false"
                  placeholder="Введите номер недели до"
                  @input="updateFilter('week_to')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Группа ответственных"
              >
                <el-select-clearable
                  v-model="filter.manager"
                  class="form-control"
                  name="manager"
                  placeholder="Начните вводить название"
                  :multiple="true"
                  clearable
                  :filterable="true"
                  :remote="true"
                  reserve-keyword
                  :remote-method="customersRoutesManagersList"
                  :loading="loading"
                  @input="updateFilter('manager')"
                  @clear="customersRoutesManagersList"
                >
                  <el-option
                    v-for="item in managers"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select-clearable>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-collapse>
    </b-card>
    <b-card no-body>
      <b-card-header>
        Рейсы
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          v-if="filter.ready"
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="withETPPercent"
            slot-scope="props"
          >
            <span v-if="props.row.editedOnEtpNumber + props.row.notEditedOnEtpNumber">{{ (props.row.editedOnEtpNumber / (props.row.editedOnEtpNumber + props.row.notEditedOnEtpNumber) * 100).toFixed(0) }}%</span>
            <span v-else>100%</span>
          </span>
          <span
            slot="withoutETPPercent"
            slot-scope="props"
          >
            <span v-if="props.row.editedOnEtpNumber + props.row.notEditedOnEtpNumber">{{ (props.row.notEditedOnEtpNumber / (props.row.editedOnEtpNumber + props.row.notEditedOnEtpNumber) * 100).toFixed(0) }}%</span>
            <span v-else>100%</span>
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
    <b-row>
      <b-col
        v-if="filter.category === 'activity' && charts.activityRoutesQuantityData.length > 1"
        lg="12"
      >
        <div
          class="g-chart-container"
        >
          <h4 class="g-chart-container__title">
            Динамика кол-ва рейсов заполняемых на ЭТП
          </h4>
          <b-form-group
            class="g-chart-container__form-group form-group-el-select"
            label=""
          >
            <el-select
              v-model="charts.activityRoutesDataFormat.value"
              class="form-control"
              name="size"
              :loading="loading"
            >
              <el-option
                v-for="item in charts.activityRoutesDataFormat.options"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              />
            </el-select>
          </b-form-group>
          <GChart
            v-if="!charts.activityRoutesDataFormat.value"
            :settings="charts.settings"
            type="LineChart"
            :data="charts.activityRoutesQuantityData"
            :options="chartOptions"
          />
          <GChart
            v-else
            :settings="charts.settings"
            type="LineChart"
            :data="charts.activityRoutesPercentageData"
            :options="chartOptions"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ElSelectClearable from '@/components/ElSelectClearable.vue';
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import {GChart} from 'vue-google-charts';
import notifications from '@/components/mixins/notifications';
import {
  customersRouteStatistics,
  routeOwnersList,
  customersContractorsList,
} from '@/services/api';
import moment from 'moment';
import tz from 'moment-timezone';
import {queryToUrl, URLToQuery} from '@/services/http';

Vue.use(ServerTable);

export default {
  name: 'RouteActivity',
  components: {
    ServerTable,
    Event,
    ElSelectClearable,
  },
  mixins: [notifications],
  data: function() {
    return {
      lastRefreshTime: null,
      managers: [],
      contractors: [],
      ticks: [],
      filter: {
        category: 'activity',
        year: 2020,
        week_from: null,
        week_to: null,
        date_from: null,
        date_to: null,
        manager: [],
        contractor: null,
        page: 1,
        limit: 4,
        ready: false,
      },
      loading: false,
      columns: [
        'week',
        'manager.name',
        'editedOnEtpNumber',
        'notEditedOnEtpNumber',
        'withETPPercent',
        'withoutETPPercent',
      ],
      options: {
        customFilters: [
          'category',
          'year',
          'week_from',
          'week_to',
          'date_from',
          'date_to',
          'manager',
          'contractor',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;
          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          if (this.filter.category === 'activity') {
            this.customersRouteStatisticsChartsData(data);
            return customersRouteStatistics(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
          }
        },
        responseAdapter({data}) {
          const filteredObj = data.items.reduce((newObj, item) => {
            let weekNumber;
            if (!moment(item.date).day()) {
              weekNumber = moment(item.date).week() - 1;
            } else {
              weekNumber = moment(item.date).week();
            }
            if (typeof newObj[weekNumber] === 'undefined') {
              newObj[weekNumber] = {
                week: `${weekNumber} неделя`,
                manager: {name: item.manager.name},
                editedOnEtpNumber: item.editedOnEtpNumber,
                notEditedOnEtpNumber: item.notEditedOnEtpNumber,
              };
            } else {
              if (!newObj[weekNumber].manager.name.includes(item.manager.name)) {
                newObj[weekNumber].manager.name = `${newObj[weekNumber].manager.name}, ${item.manager.name}`;
              }
              newObj[weekNumber].editedOnEtpNumber += item.editedOnEtpNumber;
              newObj[weekNumber].notEditedOnEtpNumber += item.notEditedOnEtpNumber;
            }
            return newObj;
          }, {});
          data.items = Object.values(filteredObj);
          return {
            data: data.items,
            count: data.items.length,
          };
        },
        headings: {
          'week': 'Период',
          'manager.name': 'Менеджер',
          'editedOnEtpNumber': 'Кол-во рейсов заполненных на ЭТП',
          'notEditedOnEtpNumber': 'Кол-во рейсов заполненных без ЭТП',
          'withETPPercent': 'Процент рейсов заполенных на ЭТП',
          'withoutETPPercent': 'Процент рейсов заполенных без ЭТП',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          show: false,
        },
        perPage: 9999,
        perPageValues: [9999],
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      charts: {
        settings: {language: 'ru', packages: ['corechart', 'line']},
        activityRoutesQuantityData: [],
        activityRoutesPercentageData: [],
        activityRoutesDataFormat: {
          options: [
            {value: 0, text: 'Количество'},
            {value: 1, text: 'Процент'},
          ],
          value: 0,
        },
        lineOptions: {
          explorer: {
            axis: 'horizontal',
            keepInBounds: true,
          },
          curveType: 'function',
          chartArea: {
            top: '30%',
            left: '10%',
            height: '50%',
            width: '80%',
          },
          height: 600,
          width: '100%',
          titlePosition: 'none',
          colors: ['#5c9bd5', '#ed7e30'],
          legend: {
            position: 'bottom',
            maxLines: 2,
            textStyle: {color: 'black', fontSize: 12},
          },
          hAxis: {
            format: 'w Неделя YY г.',
            allowContainerBoundaryTextCutoff: true,
            minTextSpacing: 20,
            maxTextLines: 2,
            slantedText: false,
            // showTextEvery: 1,
            textStyle: {
              fontSize: 12,
              color: 'black',
              bold: true,
              italic: false,
            },
          },
          vAxis: {
            viewWindow: {
              min: 0,
            },
          },
        },
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  computed: {
    chartOptions() {
      const result = this.charts.lineOptions;
      result.hAxis.ticks = this.ticks;
      return result;
    },
  },
  mounted() {
    this.customersRoutesManagersList();
    this.customersContractorsList();
    const queries = this.$router.currentRoute.query;
    /**
     * Если в URL есть параметр 'limit'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
     * Если в URL есть параметр 'page'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    const that = this;
    Event.$on('vue-tables.pagination', (data) => {
      that.filter.page = data;
      that.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    async customersRouteStatisticsChartsData(data) {
      this.loading = true;
      const response = await customersRouteStatistics(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
      if (response && response.status === 200) {
        const filteredObj = response.data.items.reduce((newObj, item) => {
          const weekNumber = moment(item.date).week();
          if (typeof newObj[weekNumber] === 'undefined') {
            newObj[weekNumber] = [
              new Date(item.date),
              item.editedOnEtpNumber,
              item.editedOnEtpNumber,
              item.notEditedOnEtpNumber,
              item.notEditedOnEtpNumber,
            ];
          } else {
            newObj[weekNumber][1] += item.editedOnEtpNumber;
            newObj[weekNumber][2] += item.editedOnEtpNumber;
            newObj[weekNumber][3] += item.notEditedOnEtpNumber;
            newObj[weekNumber][4] += item.notEditedOnEtpNumber;
          }
          return newObj;
        }, {});
        this.ticks = Object.values(filteredObj).map((item) => item[0]);
        this.charts.activityRoutesQuantityData = [
          [
            '',
            'Кол-во рейсов заполненных на ЭТП',
            {role: 'annotation'},
            'Кол-во рейсов заполненных без ЭТП',
            {role: 'annotation'},
          ],
          ...Object.values(filteredObj).map((item)=> {
            return [
              item[0],
              item[1],
              item[2] ? item[2] :null,
              item[3],
              item[4] ? item[4] :null,
            ];
          }),
        ];
        this.charts.activityRoutesPercentageData = [
          [
            '',
            'Процент рейсов заполненных на ЭТП',
            {role: 'annotation'},
            'Процент рейсов заполненных без ЭТП',
            {role: 'annotation'},
          ],
          ...[...Object.values(filteredObj)].map((item) => {
            return [
              item[0],
              (item[1] / (item[1] + item[3])) * 100,
              item[1] ? Math.round((item[1] / (item[1] + item[3])) * 100) + '%' : null,
              (item[3] / (item[1] + item[3])) * 100,
              item[3] ? Math.round((item[3] / (item[1] + item[3])) * 100) + '%' : null,
            ];
          }),
        ];
      }
      this.loading = false;
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;
        if (field === 'category') {
          this.filter.manager = null;
          this.filter.contractor = null;
          if (this.filter.category === 'activity') {
            this.columns = [
              'week',
              'manager.name',
              'editedOnEtpNumber',
              'notEditedOnEtpNumber',
              'withETPPercent',
              'withoutETPPercent',
            ];
          }
        }
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateDateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] ? moment(this.filter[field]).format('YYYY-MM-DDTHH:mm') : null;
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.category) {
        res.category = this.filter.category;
      }
      if (this.filter.year) {
        if (this.filter.week_from && (Number(this.filter.week_from) > 1)) {
          res.date_from = moment().year(this.filter.year).week(this.filter.week_from).day(1).format('YYYY-MM-DD');
        } else {
          res.date_from = `${this.filter.year}-01-01`;
        }
        if (this.filter.week_to && (Number(this.filter.week_to) < 53)) {
          res.date_to = moment().year(this.filter.year).week(this.filter.week_to).day(7).format('YYYY-MM-DD');
        } else {
          res.date_to = `${this.filter.year}-12-31`;
        }
      }
      if (this.filter.manager) {
        res.manager = this.filter.manager;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: {...this.filter}});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('category')) {
          if (params.category === 'activity') {
            this.columns = [
              'week',
              'manager.name',
              'editedOnEtpNumber',
              'notEditedOnEtpNumber',
              'withETPPercent',
              'withoutETPPercent',
            ];
          }
        }
        if (params.hasOwnProperty('date_from') && params.hasOwnProperty('date_to')) {
          const year = params.date_from.split('-')[0];
          params.year = Number(year);
        }
        if (params.hasOwnProperty('date_from')) {
          params.week_from = moment(params.date_from).week();
        }
        if (params.hasOwnProperty('date_to')) {
          if (moment(params.date_to).week() > 1) {
            if (!moment(params.date_to).day()) {
              params.week_to = moment(params.date_to).week() - 1;
            } else {
              params.week_to = moment(params.date_to).week();
            }
          }
        }
        if (params.hasOwnProperty('manager')) {
          params.manager = Number(params.manager);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        this.filter.ready = false;
        this.$nextTick(()=> {
          Object.assign(this.filter, params);
          this.filter.ready = true;
        });
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        this.filter.ready = false;
        if (params) {
          this.$nextTick(()=> {
            Object.assign(this.filter, params);
            this.filter.ready = true;
          });
        } else {
          this.filter.ready = true;
        }
      }
    },
    async customersRoutesManagersList(query) {
      this.loading = true;
      const params = {limit: 100};
      if (query) {
        params.query = query;
      }
      const response = await routeOwnersList(params);
      if (response && response.status === 200) {
        this.managers = response.data.items.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        });
      }
      if (this.filter.manager) {
        await this.customersRoutesSelectedManagersList();
      }
      this.loading = false;
    },
    async customersRoutesSelectedManagersList() {
      this.loading = true;
      const selectedManagers = {};
      this.managers.forEach((item, index) => {
        selectedManagers[`id[${index}]`] = item;
      });
      const response = await routeOwnersList(selectedManagers);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.managers = this.managers.filter((x) => x.value !== item.id);
          this.managers.unshift({
            value: item.id,
            text: item.name,
          });
        });
      }
      this.loading = false;
    },
    async customersContractorsList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (name) {
        params.name = name;
      }
      const response = await customersContractorsList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.contractors = response.data.items.map((item) => {
          return {
            value: item.contractor.id,
            text: item.contractor.name,
          };
        });
      }
      if (this.filter.contractor) {
        this.customersSelectedContractorsList();
      }
      this.loading = false;
    },
    async customersSelectedContractorsList() {
      this.loading = true;
      const response = await customersContractorsList(this.$store.state.user.ownerId, {
        'id[0]': this.filter.contractor,
      });
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.contractors = this.contractors.filter((x) => x.value !== item.contractor.id);
          this.contractors.unshift({
            value: item.contractor.id,
            text: item.contractor.name,
          });
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped>

</style>
